import note_c from "../../assets/cards/notes/note_c.png"
import note_db from "../../assets/cards/notes/note_db.png"
import note_d from "../../assets/cards/notes/note_d.png"
import note_eb from "../../assets/cards/notes/note_eb.png"
import note_e from "../../assets/cards/notes/note_e.png"
import note_f from "../../assets/cards/notes/note_f.png"
import note_gb from "../../assets/cards/notes/note_gb.png"
import note_g from "../../assets/cards/notes/note_g.png"
import note_ab from "../../assets/cards/notes/note_ab.png"
import note_a from "../../assets/cards/notes/note_a.png"
import note_bb from "../../assets/cards/notes/note_bb.png"
import note_b from "../../assets/cards/notes/note_b.png"

import interval_1 from "../../assets/cards/intervals/interval_1.png"
import interval_2 from "../../assets/cards/intervals/interval_2.png"
import interval_b2 from "../../assets/cards/intervals/interval_b2.png"
import interval_3 from "../../assets/cards/intervals/interval_3.png"
import interval_b3 from "../../assets/cards/intervals/interval_b3.png"
import interval_4 from "../../assets/cards/intervals/interval_4.png"
import interval_b5 from "../../assets/cards/intervals/interval_b5.png"
import interval_5 from "../../assets/cards/intervals/interval_5.png"
import interval_b6 from "../../assets/cards/intervals/interval_b6.png"
import interval_6 from "../../assets/cards/intervals/interval_6.png"
import interval_b7 from "../../assets/cards/intervals/interval_b7.png"
import interval_7 from "../../assets/cards/intervals/interval_7.png"

import triad_maj_1 from "../../assets/cards/triads/triad_maj.png"
import triad_maj_2 from "../../assets/cards/triads/triad_maj 2.png"
import triad_maj_3 from "../../assets/cards/triads/triad_maj 3.png"
import triad_min_1 from "../../assets/cards/triads/triad_min.png"
import triad_min_2 from "../../assets/cards/triads/triad_min 2.png"
import triad_min_3 from "../../assets/cards/triads/triad_min 3.png"
import triad_dim_1 from "../../assets/cards/triads/triad_dim.png"
import triad_dim_2 from "../../assets/cards/triads/triad_dim 2.png"
import triad_dim_3 from "../../assets/cards/triads/triad_dim 3.png"
import triad_aug_1 from "../../assets/cards/triads/triad_aug.png"
import triad_aug_2 from "../../assets/cards/triads/triad_aug 2.png"
import triad_aug_3 from "../../assets/cards/triads/triad_aug 3.png"

import seventh_maj7_1 from "../../assets/cards/sevenths/seventh_maj7.png"
import seventh_maj7_2 from "../../assets/cards/sevenths/seventh_maj7 2.png"
import seventh_min7_1 from "../../assets/cards/sevenths/seventh_min7.png"
import seventh_min7_2 from "../../assets/cards/sevenths/seventh_min7 2.png"
import seventh_dom_1 from "../../assets/cards/sevenths/seventh_dom7.png"
import seventh_dom_2 from "../../assets/cards/sevenths/seventh_dom7 2.png"
import seventh_dim_1 from "../../assets/cards/sevenths/seventh_dim7.png"
import seventh_dim_2 from "../../assets/cards/sevenths/seventh_dim7 2.png"
import seventh_min7b5_1 from "../../assets/cards/sevenths/seventh_min7b5.png"
import seventh_min7b5_2 from "../../assets/cards/sevenths/seventh_min7b5 2.png"
import seventh_minmaj7 from "../../assets/cards/sevenths/seventh_minmaj7.png"
import seventh_maj7sharp5 from "../../assets/cards/sevenths/seventh_maj7sharp5.png"

const noteCards = [
  {
    imageSrc: note_c,
    hasAccidental: false,
    cardName: "C",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_db,
    hasAccidental: true,
    cardName: "Db",
    cardSharpName: "C#",
    cardFlatName: "Db",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_d,
    hasAccidental: false,
    cardName: "D",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_eb,
    hasAccidental: true,
    cardName: "Eb",
    cardSharpName: "D#",
    cardFlatName: "Eb",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_e,
    hasAccidental: false,
    cardName: "E",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_f,
    hasAccidental: false,
    cardName: "F",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_gb,
    hasAccidental: true,
    cardName: "Gb",
    cardSharpName: "F#",
    cardFlatName: "Gb",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_g,
    hasAccidental: false,
    cardName: "G",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_ab,
    hasAccidental: true,
    cardName: "Ab",
    cardSharpName: "G#",
    cardFlatName: "Ab",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_a,
    hasAccidental: false,
    cardName: "A",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_bb,
    hasAccidental: true,
    cardName: "Bb",
    cardSharpName: "A#",
    cardFlatName: "Bb",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: note_b,
    hasAccidental: false,
    cardName: "B",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
]

const intervalCards = [
  {
    imageSrc: interval_1,
    hasAccidental: false,
    cardName: "1",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_b2,
    hasAccidental: false,
    cardName: "b2",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_2,
    hasAccidental: false,
    cardName: "2",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_b3,
    hasAccidental: false,
    cardName: "b3",
    display: true,
    inScale: true,
  },
  {
    imageSrc: interval_3,
    hasAccidental: false,
    cardName: "3",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_4,
    hasAccidental: false,
    cardName: "4",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_b5,
    hasAccidental: true,
    cardName: "b5",
    cardSharpName: "#4",
    cardFlatName: "b5",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_5,
    hasAccidental: false,
    cardName: "5",
    display: true,
    inScale: true,
  },
  {
    imageSrc: interval_b6,
    hasAccidental: true,
    cardName: "b6",
    cardSharpName: "#5",
    cardFlatName: "b6",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_6,
    hasAccidental: false,
    cardName: "6",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_b7,
    hasAccidental: false,
    cardName: "b7",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
  {
    imageSrc: interval_7,
    hasAccidental: false,
    cardName: "7",
    display: true,
    inScale: true,
    flipToFlat: false,
  },
]

const triadCards = [
  {
    imageSrc: triad_maj_1,
    cardName: "triad_maj_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_maj_2,
    cardName: "triad_maj_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_maj_3,
    cardName: "triad_maj_3",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_min_1,
    cardName: "triad_min_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_min_2,
    cardName: "triad_min_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_min_3,
    cardName: "triad_min_3",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_dim_1,
    cardName: "triad_dim_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_dim_2,
    cardName: "triad_dim_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_dim_3,
    cardName: "triad_dim_3",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_aug_1,
    cardName: "triad_aug_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_aug_2,
    cardName: "interval_b7",
    display: true,
    inScale: true,
  },
  {
    imageSrc: triad_aug_3,
    cardName: "interval_7",
    display: true,
    inScale: true,
  },
]

const seventhCards = [
  {
    imageSrc: seventh_maj7_1,
    cardName: "seventh_maj7_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_maj7_2,
    cardName: "seventh_maj7_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_min7_1,
    cardName: "seventh_min7_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_min7_2,
    cardName: "seventh_min7_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_dom_1,
    cardName: "seventh_dom_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_dom_2,
    cardName: "seventh_dom_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_min7b5_1,
    cardName: "seventh_min7b5_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_min7b5_2,
    cardName: "seventh_min7b5_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_dim_1,
    cardName: "seventh_dim_1",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_dim_2,
    cardName: "seventh_dim_2",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_maj7sharp5,
    cardName: "seventh_maj7sharp5",
    display: true,
    inScale: true,
  },
  {
    imageSrc: seventh_minmaj7,
    cardName: "seventh_minmaj7",
    display: true,
    inScale: true,
  },
]

export { noteCards, intervalCards, triadCards, seventhCards }
