import { Segment, Container, Header } from "semantic-ui-react"
import Cards from "../../containers/Cards/Cards"
import styles from "./Play.module.css"

const play = () => {
  return (
    <Segment className={styles.Segment} vertical>
      <Container text textAlign="center">
        <Header id="play" className={styles.Header} as="h1" inverted>
          PLAY THE GUITAR TAROT
        </Header>
      </Container>
      <Cards />
    </Segment>
  )
}

export default play
