const keys = [
  {
    key: "C",
    value: "C",
    text: "C",
  },
  {
    key: "C#",
    value: "C#",
    text: "C#",
  },
  {
    key: "Db",
    value: "Db",
    text: "Db",
  },
  {
    key: "D",
    value: "D",
    text: "D",
  },
  {
    key: "D#",
    value: "D#",
    text: "D#",
  },
  {
    key: "E",
    value: "E",
    text: "E",
  },
  {
    key: "F",
    value: "F",
    text: "F",
  },
  {
    key: "F#",
    value: "F#",
    text: "F#",
  },
  {
    key: "Gb",
    value: "Gb",
    text: "Gb",
  },
  {
    key: "G",
    value: "G",
    text: "G",
  },
  {
    key: "G#",
    value: "G#",
    text: "G#",
  },
  {
    key: "Ab",
    value: "Ab",
    text: "Ab",
  },
  {
    key: "A",
    value: "A",
    text: "A",
  },
  {
    key: "A#",
    value: "A#",
    text: "A#",
  },
  {
    key: "Bb",
    value: "Bb",
    text: "Bb",
  },
  {
    key: "B",
    value: "B",
    text: "B",
  },
]

export default keys
