import { Container, Menu } from "semantic-ui-react"
import styles from "./Nav.module.css"

const nav = () => {
  return (
    <Container className={styles.Container}>
      <Menu size="large" secondary>
        <Menu.Item header>THE GUITAR TAROT</Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item href="#about">ABOUT</Menu.Item>
          <Menu.Item href="#play">PLAY</Menu.Item>
          <Menu.Item href="#download">DOWNLOAD</Menu.Item>
          <Menu.Item href="mailto:lugomateo@gmail.com">CONTACT</Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  )
}

export default nav
