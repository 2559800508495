import { Button, Container, Icon, Segment, Header } from "semantic-ui-react"
import styles from "./Share.module.css"

const share = () => {
  return (
    <Segment className={styles.Segment} vertical>
      <Container textAlign="center">
        <Header id="play" className={styles.Header} as="h3">
          SHARE THE GUITAR TAROT
        </Header>
        <Button
          color="facebook"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.guitartarot.com%2F&amp;src=sdkpreparse"
          target="_blank"
        >
          <Icon name="facebook" /> Facebook
        </Button>
        <Button
          color="twitter"
          href="https://twitter.com/share?ref_src=twsrc%5Etfw"
          target="_blank"
        >
          <Icon name="twitter" /> Twitter
        </Button>
        <Button
          color="linkedin"
          href="http://www.linkedin.com/shareArticle?mini=true&url=https://www.guitartarot.com"
          target="_blank"
        >
          <Icon name="linkedin" /> LinkedIn
        </Button>
      </Container>
    </Segment>
  )
}

export default share
