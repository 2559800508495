import {
  enharmonicNotes,
  intervalsToSemitones,
  modeIntervals,
} from "./definitions"

function findStartNoteIndex(key, notes) {
  for (let i = 0; i < notes.length; i++) {
    if (notes[i].length === 1) {
      if (notes[i] === key) {
        return i
      }
    } else {
      if (notes[i].findIndex((n) => n === key) !== -1) {
        return i
      }
    }
  }
}

function findEndNoteIndex(startNoteIndex, semitones) {
  let mod = enharmonicNotes.length
  return (startNoteIndex + semitones) % mod
}

function getDegreeNote(key, interval) {
  const keys = ["C", "D", "E", "F", "G", "A", "B"]
  const mod = keys.length
  let startNote = key.match(/[ABCDEFG]/)[0]
  let scaleDegree = parseInt(interval.match(/[1-7]/)[0])
  let startNoteIndex = keys.findIndex((n) => n === startNote)
  let sum = (startNoteIndex + scaleDegree - 1) % mod
  return keys[sum]
}

function isAccidental(notePosition) {
  if (notePosition.length > 1) {
    return true
  } else {
    return false
  }
}

function scaleToIntervalCards(scale) {
  return modeIntervals[scale]
}

function scaleToNoteCards(key, scale) {
  let startNoteIndex = findStartNoteIndex(key, enharmonicNotes)
  let noteCardsArray = []
  let intervalsArray = modeIntervals[scale]
  intervalsArray.forEach((i) => {
    let semitones = intervalsToSemitones[i]
    let endIndex = findEndNoteIndex(startNoteIndex, semitones)

    if (isAccidental(enharmonicNotes[endIndex])) {
      let noteToMatch = getDegreeNote(key, i)
      let match = enharmonicNotes[endIndex].find((n) => {
        return n.match(/[ABCDEFG]/)[0] === noteToMatch
      })
      noteCardsArray.push(match)
    } else {
      noteCardsArray.push(enharmonicNotes[endIndex])
    }
  })
  return noteCardsArray
}

export { scaleToNoteCards, scaleToIntervalCards }
