const modeIntervals = {
  Chromatic: ["1", "b2", "2", "b3", "3", "4", "#4", "5", "b6", "6", "b7", "7"],
  Ionian: ["1", "2", "3", "4", "5", "6", "7"],
  Dorian: ["1", "2", "b3", "4", "5", "6", "b7"],
  Phrygian: ["1", "b2", "b3", "4", "5", "b6", "b7"],
  Lydian: ["1", "2", "3", "#4", "5", "6", "7"],
  Mixolydian: ["1", "2", "3", "4", "5", "6", "b7"],
  Aeolian: ["1", "2", "b3", "4", "5", "b6", "b7"],
  Locrian: ["1", "b2", "b3", "4", "b5", "b6", "b7"],
  "Major Pentatonic": ["1", "2", "3", "5", "6"],
  "Minor Pentatonic": ["1", "b3", "4", "5", "b7"],
  "Dorian Pentatonic": ["1", "b3", "4", "5", "6"],
  Hirajoshi: ["1", "2", "b3", "5", "b6"],
  Blues: ["1", "b3", "4", "b5", "5", "b7"],
  "Whole-tone": ["1", "2", "3", "#4", "#5", "b7"],
  "Diminished w/h": ["1", "2", "b3", "4", "b5", "b6", "6", "7"],
  "Lydian b7": ["1", "2", "3", "#4", "5", "6", "b7"],
}

const modeTriads = {
  Ionian: ["maj", "min", "min", "maj", "maj", "min", "dim"],
  Dorian: ["min", "min", "maj", "maj", "min", "dim", "maj"],
  Phrygian: ["min", "maj", "maj", "min", "dim", "maj", "min"],
  Lydian: ["maj", "maj", "min", "dim", "maj", "min", "min"],
  Mixolydian: ["maj", "min", "dim", "maj", "min", "min", "maj"],
  Aeolian: ["min", "dim", "maj", "min", "min", "maj", "maj"],
  Locrian: ["dim", "maj", "min", "min", "maj", "maj", "min"],
}

const keys = [
  "C",
  "C#",
  "Db",
  "D",
  "D#",
  "Eb",
  "E",
  "F",
  "F#",
  "Gb",
  "G",
  "G#",
  "Ab",
  "A",
  "A#",
  "Bb",
  "B",
]

const intervalsToSemitones = {
  1: 0,
  b2: 1,
  2: 2,
  b3: 3,
  3: 4,
  4: 5,
  "#4": 6,
  b5: 6,
  5: 7,
  "#5": 8,
  b6: 8,
  6: 9,
  b7: 10,
  7: 11,
}

const enharmonicNotes = [
  "C",
  ["C#", "Db"],
  "D",
  ["D#", "Eb"],
  "E",
  "F",
  ["F#", "Gb"],
  "G",
  ["G#", "Ab"],
  "A",
  ["A#", "Bb"],
  "B",
]

export {
  modeIntervals,
  modeTriads,
  keys,
  enharmonicNotes,
  intervalsToSemitones,
}
