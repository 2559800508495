import React, { Component } from "react"
import { Button, Grid, Image, Select } from "semantic-ui-react"
import styles from "./Cards.module.css"
import {
  noteCards,
  intervalCards,
  triadCards,
  seventhCards,
} from "./card-imports"
import keys from "../../utilities/music/key-select-options"
import modes from "../../utilities/music/mode-select-options"
import exercises from "../../utilities/music/exercise-select-options"
import {
  scaleToNoteCards,
  scaleToIntervalCards,
} from "../../utilities/music/functions"

class Cards extends Component {
  state = {
    deck: {
      noteCards: { cards: noteCards, displayRow: true, rowPosition: "top" },
      intervalCards: {
        cards: intervalCards,
        displayRow: false,
        rowPosition: "top",
      },
      triadCards: {
        cards: triadCards,
        displayRow: false,
        rowPosition: "bottom",
      },
      seventhCards: {
        cards: seventhCards,
        displayRow: false,
        rowPosition: "bottom",
      },
    },
    selectedKey: "C",
    selectedMode: "Chromatic",
    selectedExercise: "Note Cards",
  }

  shuffleCards = () => {
    this.zeroOpacity()

    this.waitForOpacityTransition(() => {
      let deck = { ...this.state.deck }
      Object.values(deck).forEach((cardsType) => {
        let cards = cardsType.cards
        let shuffledCards = this.shuffle(cards)
        cardsType.cards = shuffledCards
      })

      this.setState({ deck: deck }, () => {
        this.dealDeck()
        this.oneOpacity()
      })
    })
  }

  waitForOpacityTransition = (method) => {
    setTimeout(method, 300)
  }

  shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    return array
  }

  zeroOpacity = () => {
    const rows = document.getElementsByClassName("CardsRow")
    for (let row of rows) {
      row.style.transition = "0.3s all ease-in-out"
      row.style.opacity = 0
    }
  }

  oneOpacity = () => {
    const rows = document.getElementsByClassName("CardsRow")
    for (let row of rows) {
      row.style.transition = "0.3s all ease-in-out"
      row.style.opacity = 1
    }
  }

  displayOneCard = (deck, cardsType) => {
    let randomIndex = Math.floor(Math.random() * deck[cardsType].cards.length)
    deck[cardsType].cards.forEach((card, index) => {
      if (index === randomIndex) {
        card.display = true
      } else {
        card.display = false
      }
    })
  }

  displayTwoCards = (deck, cardsType) => {
    let randomIndex = Math.floor(Math.random() * deck[cardsType].cards.length)
    let otherRandomIndex = Math.floor(
      Math.random() * deck[cardsType].cards.length
    )
    while (randomIndex === otherRandomIndex) {
      otherRandomIndex = Math.floor(
        Math.random() * deck[cardsType].cards.length
      )
    }

    deck[cardsType].cards.forEach((card, index) => {
      if (index === randomIndex || index === otherRandomIndex) {
        card.display = true
      } else {
        card.display = false
      }
    })
  }

  displayKeyNoteCard = (deck) => {
    const currentKey = this.state.selectedKey
    deck.noteCards.cards.forEach((card) => {
      card.display = false
    })

    deck.noteCards.cards.forEach((card) => {
      if (card.hasAccidental) {
        if (card.cardFlatName === currentKey) {
          card.display = true
          card.flipToFlat = true
        } else if (card.cardSharpName === currentKey) {
          card.display = true
          card.flipToFlat = false
        }
      } else if (!card.hasAccidental) {
        if (card.cardName === currentKey) {
          card.display = true
        } else {
          card.display = false
        }
      }
    })
  }

  displayAllCards = (deck) => {
    Object.values(deck).forEach((cardsType) => {
      cardsType.cards.forEach((card) => {
        card.display = true
      })
    })
  }

  handleKeyChange = (event, data) => {
    const selectedKey = data.value
    this.setState({ selectedKey: selectedKey }, () => {
      this.zeroOpacity()
      this.waitForOpacityTransition(() => {
        this.filterCards()
        this.dealDeck()
        this.oneOpacity()
      })
    })
  }

  handleModeChange = (event, data) => {
    const selectedMode = data.value
    this.setState({ selectedMode: selectedMode }, () => {
      this.zeroOpacity()
      this.waitForOpacityTransition(() => {
        this.filterCards()
        this.dealDeck()
        this.oneOpacity()
      })
    })
  }

  handleExerciseChange = (event, data) => {
    const selectedExercise = data.value
    this.setState({ selectedExercise: selectedExercise }, () => {
      this.zeroOpacity()
      this.waitForOpacityTransition(() => {
        this.dealDeck()
        this.oneOpacity()
      })
    })
  }

  dealDeck = () => {
    const selectedExercise = this.state.selectedExercise
    let deck = { ...this.state.deck }
    deck.noteCards.displayRow = false
    deck.intervalCards.displayRow = false
    deck.triadCards.displayRow = false
    deck.seventhCards.displayRow = false
    this.displayAllCards(deck)

    switch (selectedExercise) {
      case "Note Cards":
        deck.noteCards.displayRow = true
        break
      case "One Note Card and All Interval Cards":
        deck.noteCards.displayRow = true
        deck.intervalCards.displayRow = true
        this.displayKeyNoteCard(deck)
        break
      case "Note Cards and Interval Cards":
        deck.noteCards.displayRow = true
        deck.intervalCards.displayRow = true
        break
      case "Daily Triad":
        deck.noteCards.displayRow = true
        deck.triadCards.displayRow = true
        this.displayOneCard(deck, "noteCards")
        this.displayOneCard(deck, "triadCards")
        break
      case "Two Triads":
        deck.noteCards.displayRow = true
        deck.triadCards.displayRow = true
        this.displayTwoCards(deck, "noteCards")
        this.displayTwoCards(deck, "triadCards")
        break
      case "Note Cards and Triad Cards":
        deck.noteCards.displayRow = true
        deck.triadCards.displayRow = true
        break
      case "Daily Seventh Chord":
        deck.noteCards.displayRow = true
        deck.seventhCards.displayRow = true
        this.displayOneCard(deck, "noteCards")
        this.displayOneCard(deck, "seventhCards")
        break
      case "Two Seventh Chords":
        deck.noteCards.displayRow = true
        deck.seventhCards.displayRow = true
        this.displayTwoCards(deck, "noteCards")
        this.displayTwoCards(deck, "seventhCards")
        break
      case "Note Cards and Seventh Chord Cards":
        deck.noteCards.displayRow = true
        deck.seventhCards.displayRow = true
        break
      default:
    }
    this.setState({ deck: deck })
  }

  filterNoteCards = () => {
    const notesInScale = scaleToNoteCards(
      this.state.selectedKey,
      this.state.selectedMode
    )
    let deck = { ...this.state.deck }
    const currentNoteCards = deck.noteCards.cards
    const updatedNoteCards = currentNoteCards.map((noteCard) => {
      if (noteCard.hasAccidental) {
        if (notesInScale.includes(noteCard.cardFlatName)) {
          noteCard.flipToFlat = true
          noteCard.inScale = true
        } else if (notesInScale.includes(noteCard.cardSharpName)) {
          noteCard.flipToFlat = false
          noteCard.inScale = true
        } else {
          noteCard.inScale = false
        }
      } else {
        if (notesInScale.includes(noteCard.cardName)) {
          noteCard.inScale = true
          noteCard.flipToFlat = false
        } else {
          noteCard.inScale = false
          noteCard.flipToFlat = false
        }
      }
      return noteCard
    })

    deck.noteCards.cards = updatedNoteCards
    this.setState({ deck: deck })
  }

  filterIntervalCards = () => {
    const intervalsInScale = scaleToIntervalCards(this.state.selectedMode)

    let deck = { ...this.state.deck }
    const currentIntervalCards = deck.intervalCards.cards
    const updatedIntervalCards = currentIntervalCards.map((intervalCard) => {
      if (intervalCard.hasAccidental) {
        if (intervalsInScale.includes(intervalCard.cardFlatName)) {
          intervalCard.flipToFlat = true
          intervalCard.inScale = true
        } else if (intervalsInScale.includes(intervalCard.cardSharpName)) {
          intervalCard.flipToFlat = false
          intervalCard.inScale = true
        } else {
          intervalCard.inScale = false
        }
      } else {
        if (intervalsInScale.includes(intervalCard.cardName)) {
          intervalCard.inScale = true
          intervalCard.flipToFlat = false
        } else {
          intervalCard.inScale = false
          intervalCard.flipToFlat = false
        }
      }
      return intervalCard
    })

    deck.intervalCards.cards = updatedIntervalCards
    this.setState({ deck: deck })
  }

  filterCards = () => {
    this.filterNoteCards()
    this.filterIntervalCards()
  }

  handleCardClick = (event) => {
    let deck = { ...this.state.deck }
    const clickedCardName = event.target.name
    const updatedNoteCards = deck.noteCards.cards.map((noteCard) => {
      if (noteCard.cardName === clickedCardName) {
        noteCard.flipToFlat = !noteCard.flipToFlat
      }
      return noteCard
    })
    deck.noteCards.cards = updatedNoteCards
    this.setState({ deck: deck })
  }

  render() {
    const cardRows = Object.entries(this.state.deck).map((cardFamily) => {
      const cardFamilyName = cardFamily[0]
      const cardFamilyInfo = cardFamily[1]
      const cards = cardFamilyInfo.cards.map((card) => {
        if (card.display && card.inScale) {
          return (
            <Image
              className={`${styles.Card} ${
                card.flipToFlat ? styles.Rotate : ""
              }`}
              src={card.imageSrc}
              size="tiny"
              key={card.cardName}
              onClick={this.handleCardClick}
              name={card.cardName}
            ></Image>
          )
        }
        return null
      })

      if (cardFamilyInfo.displayRow === true) {
        if (cardFamilyInfo.rowPosition === "top") {
          return (
            <Grid.Row
              key={cardFamilyName}
              id="top-cards-row"
              className="CardsRow"
              centered={true}
            >
              {cards}
            </Grid.Row>
          )
        } else if (cardFamilyInfo.rowPosition === "bottom") {
          return (
            <Grid.Row
              key={cardFamilyName}
              id="bottom-cards-row"
              className="CardsRow"
            >
              {cards}
            </Grid.Row>
          )
        }
      }
      return null
    })

    return (
      <Grid centered>
        <Grid centered className={styles.Cards}>
          {cardRows}
        </Grid>
        <Grid.Row id="shuffle-row">
          <Grid.Column textAlign="center">
            <Button
              onClick={this.shuffleCards}
              className={styles.Button}
              size="huge"
              color="yellow"
            >
              SHUFFLE
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row id="menu-row">
          <Select
            placeholder="Select a key"
            value={this.state.selectedKey}
            options={keys}
            onChange={this.handleKeyChange}
            className={styles.Select}
          />
          <Select
            placeholder="Select a mode"
            value={this.state.selectedMode}
            options={modes}
            onChange={this.handleModeChange}
            className={styles.Select}
          />
          <Select
            placeholder="Select an exercise"
            value={this.state.selectedExercise}
            options={exercises}
            onChange={this.handleExerciseChange}
            className={styles.Select}
          />
        </Grid.Row>
      </Grid>
    )
  }
}

export default Cards
