import Masthead from "../Masthead/Masthead"
import Nav from "../Nav/Nav"
import About from "../About/About"
import Play from "../Play/Play"
import Download from "../Download/Download"
import Share from "../Share/Share"

const layout = () => {
  return (
    <div>
      <Nav />
      <Masthead />
      <Play />
      <About />
      <Download />
      <Share />
    </div>
  )
}

export default layout
