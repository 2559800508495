// Note Cards
// 1 Note Card + All Interval Cards
// All Note Cards + All Interval Cards
// Daily Triad (1 Note + 1 triad)
// Two Chords (2 Notes + 2 triads)
// All Notecards + All Triad cards (Chromatic and Belonging to a mode)
// Daily Seventh Chord
// Two Seventh Chords
// All Notecards + All Seventh cards

const exercises = [
  {
    key: "Note Cards",
    value: "Note Cards",
    text: "Note Cards",
  },
  {
    key: "One Note Card and All Interval Cards",
    value: "One Note Card and All Interval Cards",
    text: "One Note Card and All Interval Cards",
  },
  // {
  //   key: "Note Cards and Interval Cards",
  //   value: "Note Cards and Interval Cards",
  //   text: "Note Cards and Interval Cards",
  // },
  {
    key: "Daily Triad",
    value: "Daily Triad",
    text: "Daily Triad",
  },
  {
    key: "Two Triads",
    value: "Two Triads",
    text: "Two Triads",
  },
  // {
  //   key: "Note Cards and Triad Cards",
  //   value: "Note Cards and Triad Cards",
  //   text: "Note Cards and Triad Cards",
  // },
  // {
  //   key: "Daily Seventh Chord",
  //   value: "Daily Seventh Chord",
  //   text: "Daily Seventh Chord",
  // },
  // {
  //   key: "Two Seventh Chords",
  //   value: "Two Seventh Chords",
  //   text: "Two Seventh Chords",
  // },
  // {
  //   key: "Note Cards and Seventh Chord Cards",
  //   value: "Note Cards and Seventh Chord Cards",
  //   text: "Note Cards and Seventh Chord Cards",
  // },
]

export default exercises
