import Paypal from "../Paypal/Paypal"
import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  Segment,
} from "semantic-ui-react"
import styles from "./Download.module.css"
import Book from "../../assets/images/foto_TheBook.jpg"
import Cards from "../../assets/images/cards_back.jpg"
const downloadCardsUrl =
  "https://the-guitar-tarot.s3.us-east-2.amazonaws.com/Cards_GuitarTarot_homeprint.pdf"
const downloadBookUrl =
  "https://the-guitar-tarot.s3.us-east-2.amazonaws.com/Guitar+Tarot+book_Letter_printable.pdf"

const download = () => {
  return (
    <Segment className={styles.Segment} vertical>
      <Container textAlign="center">
        <Header id="download" as="h1" inverted className={styles.Header}>
          DOWNLOAD
        </Header>
      </Container>
      <Grid columns="1" centered>
        <Grid.Row>
          <Grid.Column width="4" textAlign="center">
            <Image
              centered
              className={styles.Image}
              size="medium"
              src={Book}
            ></Image>
            <Button
              className={styles.Button}
              color="yellow"
              href={downloadBookUrl}
              size="medium"
              target="_blank"
            >
              BOOK
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="4" textAlign="center">
            <Image
              centered
              className={styles.Image}
              size="medium"
              src={Cards}
            ></Image>
            <Button
              className={styles.Button}
              color="yellow"
              href={downloadCardsUrl}
              size="medium"
              target="_blank"
            >
              CARDS
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Column className={styles.Donate} width="4" textAlign="center">
          <Header as="h3" className={styles.Donate_header}>
            DONATE TO THE GUITAR TAROT
          </Header>
          <p>
            If you've experienced gains in your creativity and overall knowledge
            of the guitar neck, please consider donating $5-$15 or an amount of
            your choice to keep the Guitar Tarot website going.
          </p>
          <Paypal className={styles.Paypal} />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default download
