const modes = [
  {
    key: "Chromatic",
    value: "Chromatic",
    text: "Chromatic",
  },
  {
    key: "Ionian",
    value: "Ionian",
    text: "Ionian (Major)",
  },
  {
    key: "Dorian",
    value: "Dorian",
    text: "Dorian",
  },
  {
    key: "Phrygian",
    value: "Phrygian",
    text: "Phrygian",
  },
  {
    key: "Lydian",
    value: "Lydian",
    text: "Lydian",
  },
  {
    key: "Mixolydian",
    value: "Mixolydian",
    text: "Mixolydian",
  },
  {
    key: "Aeolian",
    value: "Aeolian",
    text: "Aeolian (Minor)",
  },
  {
    key: "Locrian",
    value: "Locrian",
    text: "Locrian",
  },
  {
    key: "Major Pentatonic",
    value: "Major Pentatonic",
    text: "Major Pentatonic",
  },
  {
    key: "Minor Pentatonic",
    value: "Minor Pentatonic",
    text: "Minor Pentatonic",
  },
  {
    key: "Dorian Pentatonic",
    value: "Dorian Pentatonic",
    text: "Dorian Pentatonic",
  },

  {
    key: "Hirajoshi",
    value: "Hirajoshi",
    text: "Hirajoshi",
  },
  {
    key: "Blues",
    value: "Blues",
    text: "Blues",
  },
  {
    key: "Whole-tone",
    value: "Whole-tone",
    text: "Whole-tone",
  },
  {
    key: "Diminished w/h",
    value: "Diminished w/h",
    text: "Diminished w/h",
  },
  {
    key: "Diminished h/w",
    value: "Diminished h/w",
    text: "Diminished h/w",
  },
  {
    key: "Lydian b7",
    value: "Lydian b7",
    text: "Lydian b7",
  },
]

export default modes
